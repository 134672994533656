import React, { Component } from 'react';

class Loading extends Component {
    render() {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <i className="ri-loader-line spin-icon"></i>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loading;
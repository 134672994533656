import React, { Component } from 'react';
import { Col, Row, Card, Container, FormGroup, Label, CustomInput, Button } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import axios from 'axios';
import { Constants } from '../../Constants';
import { connect } from 'react-redux';
//select
import Select from 'react-select';
import './BannersList.scss';
import { auctionListAutocomplete } from '../../services/AuctionServices';
import { addBanner, getBanners, deleteBanner } from '../../services/BannersServices';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { logoutAction } from '../../redux/actions/UserActions';
import ModalCropImage from '../../components/ModalCropImage/ModalCropImage';

class BannersList extends Component {
    state = {
        breadcrumbItems: [
            { title: "Banners", link: "#" }
        ],
        image: '',
        image_thumb: '',
        image_name: '',
        image_size: '',
        auctions: [],
        auctionSelect: null,
        banners: [],
        loading: false,
        modalCropImage: false
    }

    componentDidMount() {
        this.fetchAuctions();
        this.fetchBanners();
        toast.configure();
    }

    fetchAuctions = async () => {
        await this.setState({ loading: true });
        try {
            let data = await auctionListAutocomplete({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                auctions: data.data.data.auctions
            });
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    addBanner = async () => {
        await this.setState({ loading: true });
        try {
            let response = await addBanner({
                token: this.props.sessionProps.account.token,
                id_remate: this.state.auctionSelect !== null ? this.state.auctionSelect.value : null,
                image: this.state.image
            });
            this.setState({
                auctionSelect: null,
                image: '',
                image_thumb: '',
                image_name: '',
                image_size: ''
            });
            await this.setState({ loading: false });
            toast(`Banner creado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchBanners();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    deleteBanner = async (item) => {
        confirmAlert({
            title: 'Eliminar banner',
            message: `¿Esta seguro que desea eliminar el banner seleccionado?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deleteBanner(item)
                    }
                }
            ]
        });
    }

    _deleteBanner = async (item) => {
        await this.setState({ loading: true });
        try {
            let response = await deleteBanner({
                token: this.props.sessionProps.account.token,
                id: item.id
            });
            await this.setState({ loading: false });
            toast(`Banner eliminado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchBanners();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchBanners = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getBanners({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                banners: response.data.data.banners
            })
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    closeModalCrop = async (index) => {
        await this.setState({
            modalCropImage: false
        });
    }

    handleSelectChange = async (selectedOption) => {
        await this.setState({ auctionSelect: selectedOption });
    };

    handleFileChange = async (event) => {
        let eventTargetFiles = event.target.files;
        await this.setState({ loading: true });
        let files = [];
        for (let file of eventTargetFiles) {
            files.push({
                file: await this.readFile(file),
                show: true
            });
        }
        await this.setState({
            modalCropImage: files,
            loading: false
        })
    }

    readFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    uploadImage = async (file, index) => {
        file.name = 'image_' + Date.now() + '.jpeg';
        var form = new FormData();
        form.append('file', file);
        try {
            const result = await axios.post(`${Constants.BASE_URL}banners/image_upload?type=banners`, form, {
                headers: {
                    'Authorization': this.props.sessionProps.account.token,
                    'content-type': 'multipart/form-data'
                }
            });
            this.closeModalCrop(index);
            this.setState({
                image: result.data.data.image,
                image_thumb: result.data.data.image_thumb,
                image_name: file.name,
                image_size: (file.size / 1024).toFixed(2)
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    {this.state.modalCropImage &&
                        <ModalCropImage imageSrc={this.state.modalCropImage[0]} saveImage={file => this.uploadImage(file, 0)}
                            index={0} close={(index) => this.closeModalCrop(index)} banner={true} />}
                    <Container fluid>
                        <Breadcrumbs title="Banners" breadcrumbItems={this.state.breadcrumbItems} />
                        <FormGroup row>
                            <Label className="col-md-2 col-form-label">Remate</Label>
                            <Col md={10} style={{ zIndex: 9 }}>
                                <Select
                                    name="features"
                                    options={this.state.auctions.map(item => ({ value: item.id, label: item.title }))}
                                    className="select2 select2-multiple"
                                    placeholder="Seleccionar remate correspondiente"
                                    value={this.state.auctionSelect}
                                    onChange={this.handleSelectChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label htmlFor="example-datetime-local-input" className="col-md-2 col-form-label">Imagen</Label>
                            <Col md={10}>
                                <div className="custom-file">
                                    <CustomInput type="file" name="file" className="custom-file-input" onChange={this.handleFileChange} />
                                    <Label className="custom-file-label">Seleccionar imagen</Label>
                                </div>
                            </Col>
                        </FormGroup>
                        {
                            this.state.image !== '' &&
                            <FormGroup row>
                                <Col md={2} />
                                <Col md={10}>
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={this.state.image_name}
                                                        src={`${Constants.BASE_URL}${this.state.image}`}
                                                    />
                                                </Col>
                                                <Col>
                                                    <a
                                                        href={`${Constants.BASE_URL}${this.state.image}`}
                                                        className="text-muted font-weight-bold"
                                                        target="_blank"
                                                    >
                                                        {this.state.image_name}
                                                    </a>
                                                    <p className="mb-0">
                                                        <strong>{this.state.image_size} KB</strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            </FormGroup>
                        }
                        <FormGroup row>
                            <Col md={12} className="text-right">
                                <Button type="submit" color="primary" className="ml-2" onClick={this.addBanner}>
                                    Crear banner
                                </Button>
                            </Col>
                        </FormGroup>

                        <div className="banners">
                            <div className="row">
                                <div className="col-12">
                                    <h4>Listado de banners</h4>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    this.state.banners.map(item =>
                                        <div className="col-4 item">
                                            <img src={`${Constants.BASE_URL}${item.image}`} />
                                            <div className="delete-banner" onClick={() => this.deleteBanner(item)}>
                                                <i class="ri-close-circle-line"></i>
                                            </div>
                                            <div className="remate-name">
                                                <span>{item.auction_name}</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BannersList);
import React, { Component } from 'react';
import './ModalCropImage.scss';
import { Container, Row, Col, Card, CardBody, FormGroup, Table } from 'reactstrap';
import { Constants } from '../../Constants';
import Loading from '../Loading';
import { Slider } from '@material-ui/core';
import Cropper from 'react-easy-crop'

class ModalCropImage extends Component {
    state = {
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 9 / 16,
        croppedAreaPixels: null,
        loading: false
    }

    onCropChange = (crop) => {
        this.setState({ crop })
    }

    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        await this.setState({
            croppedAreaPixels: croppedAreaPixels
        })
    }

    onZoomChange = (zoom) => {
        this.setState({ zoom })
    }

    createImage = url =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', error => reject(error))
            image.src = url
        })


    getCroppedImg = async (imageSrc, crop) => {
        await this.setState({ loading: true });
        const image = await this.createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (this.props.banner) {
            canvas.width = 1920;
            canvas.height = 1120;
        } else if (!window.skip_use_aspect_ratio) {
            canvas.width = 720;
            canvas.height = 1440;
        } else {
            canvas.width = image.width;
            canvas.height = image.height;
            crop.width = image.width;
            crop.height = image.height;
            crop.x = 0;
            crop.y = 0;
        }

        ctx.drawImage(
            image,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            canvas.width,
            canvas.height
        )
        await this.setState({ loading: false });
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob)
            }, 'image/jpeg')
        })
    }

    save = async () => {
        this.props.saveImage(
            await this.getCroppedImg(this.props.imageSrc.file, this.state.croppedAreaPixels),
            this.props.index
        );
    }

    close = () => {
        this.props.close(this.props.index);
    }

    render() {
        return (
            <div className="container-modal" id="modalCropImage">
                {this.state.loading && <Loading />}
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div className="App">
                                            <div className="crop-container">
                                                {
                                                    window.skip_use_aspect_ratio ?
                                                        <Cropper
                                                            image={this.props.imageSrc.file}
                                                            crop={this.state.crop}
                                                            zoom={this.state.zoom}
                                                            onCropChange={this.onCropChange}
                                                            onCropComplete={this.onCropComplete}
                                                            onZoomChange={this.onZoomChange}
                                                        /> :
                                                        <Cropper
                                                            image={this.props.imageSrc.file}
                                                            crop={this.state.crop}
                                                            zoom={this.state.zoom}
                                                            aspect={this.props.banner ? 12 / 7 : this.state.aspect}
                                                            onCropChange={this.onCropChange}
                                                            onCropComplete={this.onCropComplete}
                                                            onZoomChange={this.onZoomChange}
                                                        />
                                                }
                                            </div>
                                            <div className="controls">
                                                <Slider
                                                    value={this.state.zoom}
                                                    min={1}
                                                    max={3}
                                                    step={0.1}
                                                    aria-labelledby="Zoom"
                                                    onChange={(e, zoom) => this.onZoomChange(zoom)}
                                                    classes={{ container: 'slider' }}
                                                />
                                                <FormGroup className="mb-0 text-right w-100" style={{ marginTop: '1rem' }}>
                                                    <div>
                                                        <button onClick={() => this.close()} type="button" className="btn btn-secondary">Cerrar</button>
                                                        <button onClick={() => this.save()} type="button" className="btn btn-primary">Guardar</button>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default ModalCropImage;
import React, { Component } from 'react';
import './ModalShowImage.scss';
import { Container, Row, Col, Card, CardBody, FormGroup, Table } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import { Constants } from '../../Constants';

class ModalShowImage extends Component {

    close = () => {
        this.props.close();
    }

    render() {
        return (
            <div className="container-modal">
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <ImageGallery items={this.props.images.map(item =>
                                            ({
                                                id: 0, original: `${item.image.includes(Constants.BASE_URL_IMAGES) ? item.image : Constants.BASE_URL + item.image}`,
                                                thumbnail: `${item.image_thumb.includes(Constants.BASE_URL_IMAGES) ? item.image_thumb : Constants.BASE_URL + item.image_thumb}`
                                            }))}
                                                showPlayButton={false} />
                                        </div>

                                        {this.props.lotId ?
                                            <div style={{ display: 'flex' }}>
                                                {
                                                    this.props.images.map((image, index) =>
                                                        <div style={{
                                                            position: 'relative',
                                                            width: 'max-content'
                                                        }}>
                                                            {
                                                                image.image_thumb.includes(Constants.BASE_URL_IMAGES) ?
                                                                    <img
                                                                        src={`${image.image_thumb}`}
                                                                        style={{ marginBottom: '1rem', height: '5rem', marginRight: '1rem' }}
                                                                    />
                                                                    :
                                                                    <img
                                                                        src={`${Constants.BASE_URL}${image.image_thumb}`}
                                                                        style={{ marginBottom: '1rem', height: '5rem', marginRight: '1rem' }}
                                                                    />
                                                            }
                                                            <div className="delete-banner" onClick={() => this.props.deleteImage(image)}>
                                                                <i class="ri-close-circle-line"></i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            : null
                                        }

                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <button onClick={() => this.close()} type="button" className="btn btn-secondary">Cerrar</button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default ModalShowImage;
import axios from 'axios';
import { Constants } from '../Constants';

export const add = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}lots`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const edit = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}lots`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const deleteLot = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.delete(`${Constants.BASE_URL}lots/${data.id}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getLots = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.page) {
            query += query === '' ? `?page=${data.page}` : `&page=${data.page}`
        }
        if (data.auction !== null) {
            query += query === '' ? `?auction=${data.auction}` : `&auction=${data.auction}`
        }
        if (data.offset) {
            query += query === '' ? `?offset=${data.offset}` : `&offset=${data.offset}`
        }
        if (data.filterStatus) {
            query += query === '' ? `?filterStatus=${data.filterStatus}` : `&filterStatus=${data.filterStatus}`
        }
        return await axios.get(`${Constants.BASE_URL}lots${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getLotsForDelivery = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.auction !== null) {
            query += '?auction=' + data.auction;
        }
        return await axios.get(`${Constants.BASE_URL}customers/pending_lots/${data.userId}${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const deliveryLots = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}lots/deliveryLots`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getBestOffers = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}offers/bestOffers/${data.lot}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const assignForceWin = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}lots/assignForceWin`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const removeWin = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}lots/removeWin`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const calculateTotals = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}lots/calculateTotals`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const deleteOffer = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}offers/delete`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}
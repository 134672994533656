import React, { Component } from 'react';
import './ActualAuctionLogs.scss';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    Col, Row, Card, CardBody, Container, Table,
    Nav, NavItem, NavLink, Input
} from 'reactstrap';
import moment from 'moment';
import { getLogs } from '../../services/AuctionServices';
import { connect } from 'react-redux';
import { logoutAction } from '../../redux/actions/UserActions';

class ActualAuctionLogs extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remate actual", link: "#" }
        ],
        auctions: [],
        auctionActive: null,
        loading: false,
        interval: null
    }

    componentDidMount = async () => {
        this.fetchLogs(true);
        let interval = setInterval(() => {
            this.fetchLogs();
        }, 5000);
        await this.setState({
            interval: interval
        })
    }

    componentWillUnmount = () => {
        clearInterval(this.state.interval);
    }

    fetchLogs = async (showLoading = false) => {
        showLoading && await this.setState({ loading: true })
        try {
            let response = await getLogs({
                token: this.props.sessionProps.account.token,
            });
            let auctionActive = this.state.auctionActive;
            if (auctionActive !== null) {
                response.data.data.map(item => {
                    if (auctionActive.id === item.id) {
                        auctionActive = item;
                    }
                })
            }
            else {
                if (response.data.data.length > 0) {
                    auctionActive = response.data.data[0];
                }
            }
            await this.setState({
                auctions: response.data.data,
                loading: false,
                auctionActive: auctionActive
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
        }
    }

    toggleTab = async (tab) => {
        if (this.state.auctionActive !== tab) {
            await this.setState({
                auctionActive: tab
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Logs" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="without-padding">
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            {
                                                this.state.auctions.map(item =>
                                                    <NavItem>
                                                        <NavLink onClick={() => { this.toggleTab(item); }}
                                                            className={`${this.state.auctionActive?.id === item.id && 'active'} font-weight-bold p-3`}>
                                                            {item.title}
                                                        </NavLink>
                                                    </NavItem>
                                                )
                                            }
                                        </Nav>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Lote</th>
                                                        <th>Nombre</th>
                                                        <th>Cliente</th>
                                                        <th>Oferta</th>
                                                        <th>Fecha</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.auctionActive?.logs.map(item =>
                                                            <tr style={{ color: `${item.offerObject !== null && !item.offerObject.is_active ? 'red' : '#505d69'}` }}>
                                                                <th scope="row">{item.lot.id_lot}</th>
                                                                <td>{item.lot.title}</td>
                                                                <td>{item.user && item.user.name}</td>
                                                                <td>{item.money} {item.offer}</td>
                                                                <td>{`${moment(item.datetime).format('dddd, D. MMMM HH:mm')} hs`}</td>
                                                                <td>{`${item.offerObject !== null && item.offerObject.is_automatic_offer ? 'Automática' : ''}`}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActualAuctionLogs);